import clsx from "clsx";
import React, { forwardRef } from "react";
import { BasicRichTextEditor, getSimpleRichText } from "@monwbi/lexical";
import { emptyInputClassName } from "~/components/inputs/utils";
import type { InputProps } from "~/types/forms/inputs";
import { isJsonString } from "~/utils/general";
type RichtextInputProps = React.ComponentPropsWithRef<typeof BasicRichTextEditor> & InputProps;
export const RichtextInput = forwardRef<HTMLInputElement, RichtextInputProps>(function RichtextInput({
  unstyled = false,
  isError,
  placeholder,
  className,
  ...props
}, forwardedRef) {
  if (props.readOnly) {
    const value = props.value ?? `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"/","type":"text","version":1}],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}`;
    return <BasicRichTextEditor id={props.name}
    // <ContentEditable /> does not seem to re-render when passing from readOnly to not readOnly so we need to force it with a key
    key={`${props.name}-${props.readOnly}`} ref={forwardedRef} placeholder={placeholder && !isJsonString(placeholder) ? getSimpleRichText(placeholder) : placeholder} {...props} className={clsx(!props.value && emptyInputClassName, "overflow-auto", className)} value={value} />;
  }
  return <BasicRichTextEditor id={props.name}
  // <ContentEditable /> does not seem to re-render when passing from readOnly to not readOnly so we need to force it with a key
  key={`${props.name}-${props.readOnly}`} ref={forwardedRef} placeholder={placeholder && !isJsonString(placeholder) ? getSimpleRichText(placeholder) : placeholder} {...props} className={clsx(!unstyled && ["border rounded-[3px] text-neutral-900 shadow-sm ", isError ? "border-error" : "border-grey-blue-80"], className)} editorClassName={clsx("p-2 h-full", props.editorClassName)} />;
});