// src/lexical.tsx
import { createHeadlessEditor } from "@lexical/headless";
import { AutoLinkNode, LinkNode as LinkNode2 } from "@lexical/link";
import { ListItemNode, ListNode as ListNode2 } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext as useLexicalComposerContext8 } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import clsx3 from "clsx";
import { $getRoot, ParagraphNode } from "lexical";
import React, { forwardRef, useEffect as useEffect9, useState as useState4 } from "react";

// src/plugins/clear-editor-plugin.tsx
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { CLEAR_HISTORY_COMMAND, COMMAND_PRIORITY_EDITOR } from "lexical";
import { useEffect as useEffect2 } from "react";

// src/commands.ts
import { createCommand } from "lexical";
var RESET_EDITOR_COMMAND = createCommand();

// src/shared/useLayoutEffect.ts
import { useEffect, useLayoutEffect } from "react";

// src/shared/canUseDOM.ts
var CAN_USE_DOM = typeof window !== "undefined" && typeof window.document !== "undefined" && typeof window.document.createElement !== "undefined";

// src/shared/useLayoutEffect.ts
var useLayoutEffectImpl = CAN_USE_DOM ? useLayoutEffect : useEffect;
var useLayoutEffect_default = useLayoutEffectImpl;

// src/plugins/clear-editor-plugin.tsx
var handleReset = (editor, defaultValue) => {
  if (typeof defaultValue === "string") {
    const initialEditorState = editor.parseEditorState(defaultValue);
    editor.setEditorState(initialEditorState);
    editor.dispatchCommand(CLEAR_HISTORY_COMMAND, void 0);
  }
};
function ResetEditorPlugin({ isDirty, defaultValue, currentValue }) {
  const [editor] = useLexicalComposerContext();
  useEffect2(() => {
    if (currentValue === defaultValue && isDirty) {
      editor.dispatchCommand(RESET_EDITOR_COMMAND, void 0);
    }
  }, [isDirty, currentValue, defaultValue, editor]);
  useLayoutEffect_default(() => {
    return editor.registerCommand(
      RESET_EDITOR_COMMAND,
      () => {
        editor.update(() => {
          handleReset(editor, defaultValue);
        });
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);
  return null;
}

// src/plugins/floating-link-editor-plugin.tsx
import { CheckIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { $isAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useLexicalComposerContext as useLexicalComposerContext2 } from "@lexical/react/LexicalComposerContext";
import { $findMatchingParent, mergeRegister } from "@lexical/utils";
import clsx from "clsx";
import {
  $getSelection,
  $isRangeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  SELECTION_CHANGE_COMMAND
} from "lexical";
import { useCallback, useEffect as useEffect3, useRef, useState } from "react";
import { createPortal } from "react-dom";

// src/plugins/auto-link-plugin.tsx
import { AutoLinkPlugin, createLinkMatcherWithRegExp } from "@lexical/react/LexicalAutoLinkPlugin";
import { jsx } from "react/jsx-runtime";
var URL_REGEX = /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
var EMAIL_REGEX = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
var MATCHERS = [
  createLinkMatcherWithRegExp(URL_REGEX, (text) => {
    return text.startsWith("http") ? text : `https://${text}`;
  }),
  createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => {
    return `mailto:${text}`;
  })
];
function LexicalAutoLinkPlugin() {
  return /* @__PURE__ */ jsx(AutoLinkPlugin, { matchers: MATCHERS });
}

// src/utils/getSelectedNode.ts
import { $isAtNodeEnd } from "@lexical/selection";
function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
  }
}

// src/utils/setFloatingElemPositionForLinkEditor.ts
var VERTICAL_GAP = 10;
var HORIZONTAL_OFFSET = 5;
function setFloatingElemPositionForLinkEditor(targetRect, floatingElem, anchorElem, verticalGap = VERTICAL_GAP, horizontalOffset = HORIZONTAL_OFFSET) {
  const scrollerElem = anchorElem.parentElement;
  if (targetRect === null || !scrollerElem) {
    floatingElem.style.opacity = "0";
    floatingElem.style.transform = "translate(-10000px, -10000px)";
    return;
  }
  const floatingElemRect = floatingElem.getBoundingClientRect();
  const anchorElementRect = anchorElem.getBoundingClientRect();
  const editorScrollerRect = scrollerElem.getBoundingClientRect();
  let top = targetRect.top - verticalGap;
  let left = targetRect.left - horizontalOffset;
  if (top < editorScrollerRect.top) {
    top += floatingElemRect.height + targetRect.height + verticalGap * 2;
  }
  if (left + floatingElemRect.width > editorScrollerRect.right) {
    left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
  }
  top -= anchorElementRect.top;
  left -= anchorElementRect.left;
  floatingElem.style.opacity = "1";
  floatingElem.style.transform = `translate(${left}px, ${top}px)`;
}

// src/utils/url.ts
var SUPPORTED_URL_PROTOCOLS = /* @__PURE__ */ new Set(["http:", "https:", "mailto:", "sms:", "tel:"]);
function sanitizeUrl(url) {
  try {
    const parsedUrl = new URL(url);
    if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
      return "about:blank";
    }
  } catch {
    return url;
  }
  return url;
}
var urlRegExp = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
);
function validateUrl(url) {
  return url === "https://" || urlRegExp.test(url);
}

// src/plugins/floating-link-editor-plugin.tsx
import { jsx as jsx2, jsxs } from "react/jsx-runtime";
function FloatingLinkEditor({
  editor,
  isLink,
  setIsLink,
  anchorElem,
  isLinkEditMode,
  setIsLinkEditMode
}) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const [linkUrl, setLinkUrl] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [editedLinkUrl, setEditedLinkUrl] = useState("https://");
  const [lastSelection, setLastSelection] = useState(null);
  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const linkParent = $findMatchingParent(node, $isLinkNode);
      if (linkParent) {
        setLinkUrl(linkParent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;
    if (editorElem === null) {
      return;
    }
    const rootElement = editor.getRootElement();
    if (selection !== null && nativeSelection !== null && rootElement !== null && rootElement.contains(nativeSelection.anchorNode) && editor.isEditable()) {
      const domRect = nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
      if (domRect) {
        domRect.y += 40;
        setFloatingElemPositionForLinkEditor(domRect, editorElem, anchorElem);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== "link-input") {
      if (rootElement !== null) {
        setFloatingElemPositionForLinkEditor(null, editorElem, anchorElem);
      }
      setLastSelection(null);
      setIsLinkEditMode(false);
      setLinkUrl("");
    }
    return true;
  }, [anchorElem, editor, setIsLinkEditMode]);
  useEffect3(() => {
    const scrollerElem = anchorElem.parentElement;
    const update = () => {
      editor.getEditorState().read(() => {
        updateLinkEditor();
      });
    };
    window.addEventListener("resize", update);
    if (scrollerElem) {
      scrollerElem.addEventListener("scroll", update);
    }
    return () => {
      window.removeEventListener("resize", update);
      if (scrollerElem) {
        scrollerElem.removeEventListener("scroll", update);
      }
    };
  }, [anchorElem.parentElement, editor, updateLinkEditor]);
  useEffect3(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isLink) {
            setIsLink(false);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH
      )
    );
  }, [editor, updateLinkEditor, setIsLink, isLink]);
  useEffect3(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);
  useEffect3(() => {
    if (isLinkEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLinkEditMode, isLink]);
  const monitorInputInteraction = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLinkSubmission();
    } else if (event.key === "Escape") {
      event.preventDefault();
      setIsLinkEditMode(false);
    }
  };
  const validateUrl2 = (value) => {
    return MATCHERS.some((matchFunction) => {
      const matchResult = matchFunction(value);
      return matchResult !== null;
    });
  };
  const handleLinkSubmission = () => {
    if (lastSelection !== null) {
      if (validateUrl2(editedLinkUrl)) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(editedLinkUrl));
        setIsValidUrl(true);
      } else {
        setIsValidUrl(false);
        return;
      }
      setEditedLinkUrl("https://");
      setIsLinkEditMode(false);
    }
  };
  return /* @__PURE__ */ jsx2("div", { ref: editorRef, className: "flex absolute top-0 left-0 z-10 max-w-sm w-full bg-white shadow-xl transition-opacity will-change-transform", children: !isLink ? null : isLinkEditMode ? /* @__PURE__ */ jsxs("div", { className: "flex flex-col m-1 divide-y w-full", children: [
    /* @__PURE__ */ jsxs("div", { className: "flex flex-col p-3 w-full", children: [
      /* @__PURE__ */ jsx2(
        "input",
        {
          ref: inputRef,
          className: clsx(
            "link-input block box-border px-2 py-1 text-black rounded-sm focus:outline-none ring-0 relative",
            isValidUrl ? "border-aubergine/60" : "border-error"
          ),
          value: editedLinkUrl,
          onChange: (event) => {
            setEditedLinkUrl(event.target.value);
          },
          onKeyDown: (event) => {
            monitorInputInteraction(event);
          }
        }
      ),
      !isValidUrl && /* @__PURE__ */ jsx2("p", { className: "text-error pt-2", children: "Erreur: url non valide" })
    ] }),
    /* @__PURE__ */ jsxs("div", { className: "py-4 px-14 flex justify-center items-center gap-x-6", children: [
      /* @__PURE__ */ jsx2(
        "button",
        {
          type: "button",
          className: "inline-block p-1 rounded-[3px] hover:bg-aubergine/20",
          tabIndex: 0,
          onMouseDown: (event) => event.preventDefault(),
          onClick: () => {
            setIsLinkEditMode(false);
          },
          children: /* @__PURE__ */ jsx2(TrashIcon, { className: "h-5 w-5" })
        }
      ),
      /* @__PURE__ */ jsx2(
        "button",
        {
          type: "button",
          className: "inline-block p-1 rounded-[3px] hover:bg-aubergine/20",
          tabIndex: 0,
          onMouseDown: (event) => event.preventDefault(),
          onClick: handleLinkSubmission,
          children: /* @__PURE__ */ jsx2(CheckIcon, { className: "h-5 w-5" })
        }
      )
    ] })
  ] }) : /* @__PURE__ */ jsxs("div", { className: "flex flex-col m-1 divide-y w-full", children: [
    /* @__PURE__ */ jsx2("a", { href: sanitizeUrl(linkUrl), target: "_blank", rel: "noopener noreferrer", className: "block break-words p-2 underline text-black", children: linkUrl }),
    /* @__PURE__ */ jsxs("div", { className: "py-4 px-14 flex justify-center items-center gap-x-6", children: [
      /* @__PURE__ */ jsx2(
        "button",
        {
          type: "button",
          className: "inline-block p-1 rounded-[3px] hover:bg-aubergine/20",
          tabIndex: 0,
          onMouseDown: (event) => event.preventDefault(),
          onClick: () => {
            setEditedLinkUrl(linkUrl);
            setIsLinkEditMode(true);
          },
          children: /* @__PURE__ */ jsx2(PencilIcon, { className: "h-5 w-5" })
        }
      ),
      /* @__PURE__ */ jsx2(
        "button",
        {
          type: "button",
          className: "hover:bg-aubergine/20 inline-block p-1 rounded-[3px]",
          tabIndex: 0,
          onMouseDown: (event) => event.preventDefault(),
          onClick: () => {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
          },
          children: /* @__PURE__ */ jsx2(TrashIcon, { className: "h-5 w-5" })
        }
      )
    ] })
  ] }) });
}
function useFloatingLinkEditorToolbar(editor, anchorElem, isLinkEditMode, setIsLinkEditMode) {
  const [activeEditor, setActiveEditor] = useState(editor);
  const [isLink, setIsLink] = useState(false);
  useEffect3(() => {
    function updateToolbar() {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const node = getSelectedNode(selection);
        const linkParent = $findMatchingParent(node, $isLinkNode);
        const autoLinkParent = $findMatchingParent(node, $isAutoLinkNode);
        if (linkParent !== null && autoLinkParent === null) {
          setIsLink(true);
        } else {
          setIsLink(false);
        }
      }
    }
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          setActiveEditor(newEditor);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        CLICK_COMMAND,
        (payload) => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const linkNode = $findMatchingParent(node, $isLinkNode);
            if ($isLinkNode(linkNode) && (payload.metaKey || payload.ctrlKey)) {
              window.open(linkNode.getURL(), "_blank");
              return true;
            }
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);
  return createPortal(
    /* @__PURE__ */ jsx2(
      FloatingLinkEditor,
      {
        editor: activeEditor,
        isLink,
        anchorElem,
        setIsLink,
        isLinkEditMode,
        setIsLinkEditMode
      }
    ),
    anchorElem
  );
}
function FloatingLinkEditorPlugin({
  anchorElem = document.body,
  isLinkEditMode,
  setIsLinkEditMode
}) {
  const [editor] = useLexicalComposerContext2();
  return useFloatingLinkEditorToolbar(editor, anchorElem, isLinkEditMode, setIsLinkEditMode);
}

// src/plugins/link-plugin.tsx
import { LinkNode, TOGGLE_LINK_COMMAND as TOGGLE_LINK_COMMAND2, toggleLink } from "@lexical/link";
import { useLexicalComposerContext as useLexicalComposerContext3 } from "@lexical/react/LexicalComposerContext";
import { mergeRegister as mergeRegister2 } from "@lexical/utils";
import { $getSelection as $getSelection2, $isElementNode, $isRangeSelection as $isRangeSelection2, COMMAND_PRIORITY_LOW as COMMAND_PRIORITY_LOW2, PASTE_COMMAND } from "lexical";
import { useEffect as useEffect4 } from "react";
function LinkPlugin({ validateUrl: validateUrl2 }) {
  const [editor] = useLexicalComposerContext3();
  useEffect4(() => {
    if (!editor.hasNodes([LinkNode])) {
      throw new Error("LinkPlugin: LinkNode not registered on editor");
    }
    return mergeRegister2(
      editor.registerCommand(
        TOGGLE_LINK_COMMAND2,
        (payload) => {
          if (payload === null) {
            toggleLink(payload);
            return true;
          } else if (typeof payload === "string") {
            if (validateUrl2 === void 0 || validateUrl2(payload)) {
              toggleLink(payload);
              return true;
            }
            return false;
          } else {
            const { url, target, rel, title } = payload;
            toggleLink(url, { rel, target, title });
            return true;
          }
        },
        COMMAND_PRIORITY_LOW2
      ),
      validateUrl2 !== void 0 ? editor.registerCommand(
        PASTE_COMMAND,
        (event) => {
          const selection = $getSelection2();
          if (!$isRangeSelection2(selection) || selection.isCollapsed() || !(event instanceof ClipboardEvent) || event.clipboardData == null) {
            return false;
          }
          const clipboardText = event.clipboardData.getData("text");
          if (!validateUrl2(clipboardText)) {
            return false;
          }
          if (!selection.getNodes().some((node) => $isElementNode(node))) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND2, clipboardText);
            event.preventDefault();
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW2
      ) : () => {
      }
    );
  }, [editor, validateUrl2]);
  return null;
}

// src/plugins/listen-initialValue-plugin.tsx
import { useLexicalComposerContext as useLexicalComposerContext4 } from "@lexical/react/LexicalComposerContext";
import { useEffect as useEffect5 } from "react";

// src/constant.ts
import { BOLD_ITALIC_STAR, BOLD_STAR, ITALIC_STAR, ORDERED_LIST, STRIKETHROUGH, UNORDERED_LIST } from "@lexical/markdown";
var EMPTY_RICHTEXT = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';
var TRANSFORMERS = [UNORDERED_LIST, ORDERED_LIST, BOLD_ITALIC_STAR, BOLD_STAR, STRIKETHROUGH, ITALIC_STAR];

// src/plugins/listen-initialValue-plugin.tsx
function ListenValueChangePlugin({ value }) {
  const [editor] = useLexicalComposerContext4();
  useEffect5(() => {
    if (value && value !== EMPTY_RICHTEXT) {
      const initialEditorState = editor.parseEditorState(value);
      editor.setEditorState(initialEditorState);
    }
  }, [value, editor]);
  return null;
}

// src/plugins/max-length-plugin.tsx
import { useLexicalComposerContext as useLexicalComposerContext5 } from "@lexical/react/LexicalComposerContext";
import { trimTextContentFromAnchor } from "@lexical/selection";
import { $getSelection as $getSelection3, $isRangeSelection as $isRangeSelection3, RootNode } from "lexical";
import { useEffect as useEffect6, useState as useState2 } from "react";
import { jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
function MaxLengthPlugin({ maxLength }) {
  const [editor] = useLexicalComposerContext5();
  const [charactersCount, setCharactersCount] = useState2(0);
  useEffect6(() => {
    return editor.registerNodeTransform(RootNode, (rootNode) => {
      const selection = $getSelection3();
      if (!$isRangeSelection3(selection) || !selection.isCollapsed()) {
        return;
      }
      const prevTextContent = editor.getEditorState().read(() => rootNode.getTextContent());
      const textContent = rootNode.getTextContent();
      setCharactersCount(textContent.length);
      if (prevTextContent !== textContent) {
        const textLength = textContent.length;
        const delCount = textLength - maxLength;
        const anchor = selection.anchor;
        if (delCount > 0) {
          trimTextContentFromAnchor(editor, anchor, delCount);
        }
      }
    });
  }, [editor, maxLength]);
  return /* @__PURE__ */ jsxs2("p", { className: "absolute bottom-0 py-1 border-neutral-200 border-t w-full flex justify-end items-center text-neutral-500 px-2", children: [
    /* @__PURE__ */ jsx3("span", { className: "text-neutral-600 text-xs font-semibold leading-none", children: charactersCount }),
    " /",
    /* @__PURE__ */ jsx3("span", { className: "text-xs font-semibold leading-none", children: maxLength })
  ] });
}

// src/plugins/tab-focus-plugin.ts
import { useLexicalComposerContext as useLexicalComposerContext6 } from "@lexical/react/LexicalComposerContext";
import { $getSelection as $getSelection4, $isRangeSelection as $isRangeSelection4, $setSelection, FOCUS_COMMAND } from "lexical";
import { useEffect as useEffect7 } from "react";
var COMMAND_PRIORITY_LOW3 = 1;
var TAB_TO_FOCUS_INTERVAL = 100;
var lastTabKeyDownTimestamp = 0;
var hasRegisteredKeyDownListener = false;
function registerKeyTimeStampTracker() {
  window.addEventListener(
    "keydown",
    (event) => {
      if (event.keyCode === 9) {
        lastTabKeyDownTimestamp = event.timeStamp;
      }
    },
    true
  );
}
function TabFocusPlugin() {
  const [editor] = useLexicalComposerContext6();
  useEffect7(() => {
    if (!hasRegisteredKeyDownListener) {
      registerKeyTimeStampTracker();
      hasRegisteredKeyDownListener = true;
    }
    return editor.registerCommand(
      FOCUS_COMMAND,
      (event) => {
        const selection = $getSelection4();
        if ($isRangeSelection4(selection)) {
          if (lastTabKeyDownTimestamp + TAB_TO_FOCUS_INTERVAL > event.timeStamp) {
            $setSelection(selection.clone());
          }
        }
        return false;
      },
      COMMAND_PRIORITY_LOW3
    );
  }, [editor]);
  return null;
}

// src/plugins/toolbar-plugin.tsx
import {
  ArrowPathIcon,
  ArrowRightIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  Bars3BottomLeftIcon,
  Bars3BottomRightIcon,
  Bars3Icon,
  ListBulletIcon,
  PaperClipIcon
} from "@heroicons/react/24/outline";
import { $isLinkNode as $isLinkNode2, TOGGLE_LINK_COMMAND as TOGGLE_LINK_COMMAND3 } from "@lexical/link";
import { $isListNode, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode, REMOVE_LIST_COMMAND } from "@lexical/list";
import { useLexicalComposerContext as useLexicalComposerContext7 } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import { $getNearestNodeOfType, mergeRegister as mergeRegister3 } from "@lexical/utils";
import clsx2 from "clsx";
import {
  $getSelection as $getSelection5,
  $isRangeSelection as $isRangeSelection5,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_NORMAL,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  KEY_MODIFIER_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND as SELECTION_CHANGE_COMMAND2,
  UNDO_COMMAND
} from "lexical";
import { useCallback as useCallback2, useEffect as useEffect8, useRef as useRef2, useState as useState3 } from "react";
import { jsx as jsx4, jsxs as jsxs3 } from "react/jsx-runtime";
var LowPriority = 1;
function ToolbarPlugin({ setIsLinkEditMode, disabled = false }) {
  const [editor] = useLexicalComposerContext7();
  const toolbarRef = useRef2(null);
  const [canUndo, setCanUndo] = useState3(false);
  const [canRedo, setCanRedo] = useState3(false);
  const [blockType, setBlockType] = useState3("paragraph");
  const [isBold, setIsBold] = useState3(false);
  const [isItalic, setIsItalic] = useState3(false);
  const [isStrikethrough, setIsStrikethrough] = useState3(false);
  const [isLink, setIsLink] = useState3(false);
  const updateToolbar = useCallback2(() => {
    const selection = $getSelection5();
    if ($isRangeSelection5(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode2(parent) || $isLinkNode2(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);
  useEffect8(() => {
    return mergeRegister3(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND2,
        (_payload) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);
  useEffect8(() => {
    return editor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event = payload;
        const { code, ctrlKey, metaKey } = event;
        if (code === "KeyK" && (ctrlKey || metaKey)) {
          event.preventDefault();
          if (!isLink) {
            setIsLinkEditMode(true);
          } else {
            setIsLinkEditMode(false);
          }
          return editor.dispatchCommand(TOGGLE_LINK_COMMAND3, sanitizeUrl("https://"));
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor, isLink, setIsLinkEditMode]);
  const insertLink = useCallback2(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND3, sanitizeUrl("https://"));
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND3, null);
    }
  }, [editor, isLink]);
  const formatBulletList = useCallback2(() => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, void 0);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0);
    }
  }, [editor, blockType]);
  const formatNumberedList = useCallback2(() => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, void 0);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0);
    }
  }, [editor, blockType]);
  return /* @__PURE__ */ jsxs3("div", { className: clsx2("flex w-full items-center gap-1 bg-transparent border-aubergine/20 border-b px-2 py-1"), ref: toolbarRef, children: [
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled: disabled || !canUndo,
        onClick: () => {
          editor.dispatchCommand(UNDO_COMMAND, void 0);
        },
        className: "flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        "aria-label": "Undo",
        children: /* @__PURE__ */ jsx4(ArrowUturnLeftIcon, { className: "w-4 h-4" })
      }
    ),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled: disabled || !canRedo,
        onClick: () => {
          editor.dispatchCommand(REDO_COMMAND, void 0);
        },
        className: "flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        "aria-label": "Redo",
        children: /* @__PURE__ */ jsx4(ArrowUturnRightIcon, { className: "w-4 h-4" })
      }
    ),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled,
        onClick: () => {
          editor.dispatchCommand(RESET_EDITOR_COMMAND, void 0);
        },
        className: "flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        "aria-label": "Redo",
        children: /* @__PURE__ */ jsx4(ArrowPathIcon, { className: "w-4 h-4" })
      }
    ),
    /* @__PURE__ */ jsx4(Divider, {}),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled,
        onClick: () => {
          editor.focus();
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        },
        className: "flex h-8 w-8 items-center justify-center font-bold rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        "aria-label": "Format Bold",
        "data-active": isBold,
        children: "B"
      }
    ),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled,
        onClick: () => {
          editor.focus();
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        },
        className: "flex h-8 w-8 items-center justify-center rounded-md italic text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        "aria-label": "Format Italics",
        "data-active": isItalic,
        children: "I"
      }
    ),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled,
        onClick: () => {
          editor.focus();
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
        },
        className: "flex h-8 w-8 items-center line-through justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        "aria-label": "Format Strikethrough",
        "data-active": isStrikethrough,
        children: "S"
      }
    ),
    /* @__PURE__ */ jsx4(Divider, {}),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled,
        className: "flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        onClick: formatBulletList,
        "aria-label": "Bullet List",
        children: /* @__PURE__ */ jsx4(ListBulletIcon, { className: "h-4 w-4" })
      }
    ),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled,
        className: "flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        onClick: formatNumberedList,
        "aria-label": "Numbered List",
        children: /* @__PURE__ */ jsx4("svg", { width: "17", height: "15", viewBox: "0 0 17 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ jsx4(
          "path",
          {
            d: "M7.5 11.25H16.25V12.5H7.5V11.25ZM7.5 2.5H16.25V3.75H7.5V2.5ZM2.5 5V0H1.25V0.625H0V1.875H1.25V5H0V6.25H3.75V5H2.5ZM3.75 15H0V12.5C0 12.1685 0.131696 11.8505 0.366116 11.6161C0.800537 11.3817 0.918479 11.25 1.25 11.25H2.5V10H0V8.75H2.5C2.83152 8.75 3.14946 8.8817 3.38388 9.11612C3.6183 9.35054 3.75 9.66848 3.75 10V11.25C3.75 11.5815 3.6183 11.8995 3.38388 12.1339C3.14946 12.3683 2.83152 12.5 2.5 12.5H1.25V13.75H3.75V15Z",
            fill: "currentColor"
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsx4(Divider, {}),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        datatype: "hyperlink",
        disabled,
        className: clsx2(
          "flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 data-[active='true']:bg-royal-blue-40 disabled:text-opacity-60 disabled:hover:bg-transparent",
          { "bg-royal-blue-40": isLink }
        ),
        onClick: insertLink,
        "aria-label": "Insert Link",
        children: /* @__PURE__ */ jsx4(PaperClipIcon, { className: "w-4 h-4" })
      }
    ),
    /* @__PURE__ */ jsx4(Divider, {}),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled,
        className: "flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        onClick: () => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        },
        "aria-label": "Numbered List",
        children: /* @__PURE__ */ jsx4(Bars3BottomLeftIcon, { className: "w-4 h-4" })
      }
    ),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled,
        className: "flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        onClick: () => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        },
        "aria-label": "Numbered List",
        children: /* @__PURE__ */ jsx4(Bars3Icon, { className: "w-4 h-4" })
      }
    ),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled,
        className: "flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        onClick: () => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        },
        "aria-label": "Numbered List",
        children: /* @__PURE__ */ jsx4(Bars3BottomRightIcon, { className: "w-4 h-4" })
      }
    ),
    /* @__PURE__ */ jsx4(Divider, {}),
    /* @__PURE__ */ jsx4(
      "button",
      {
        type: "button",
        disabled,
        className: "flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800",
        onClick: () => {
          editor.focus();
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, void 0);
        },
        "aria-label": "Indent",
        children: /* @__PURE__ */ jsx4(ArrowRightIcon, { className: "w-4 h-4" })
      }
    )
  ] });
}
var Divider = () => /* @__PURE__ */ jsx4("span", { className: "!mx-2 block h-6 w-[1px] bg-neutral-400" });

// src/lexical.tsx
import { Fragment, jsx as jsx5, jsxs as jsxs4 } from "react/jsx-runtime";
var getSimpleRichText = (value) => {
  return JSON.stringify({
    root: {
      children: [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: value,
              type: "text",
              version: 1
            }
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1
        }
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1
    }
  });
};
function getLexicalTextContentLength(value) {
  const editor = createHeadlessEditor({
    nodes: [ParagraphNode, ListNode2, ListItemNode],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {
    }
  });
  editor.setEditorState(editor.parseEditorState(value));
  const length = editor.getEditorState().read(() => {
    const rootNode = $getRoot();
    const textContent = rootNode.getTextContent();
    return textContent.length;
  });
  return length;
}
var LexicalPlaceholder = forwardRef(function Placeholder({ value = void 0, toolbox = true, className, id, ...props }, forwardedRef) {
  const [editor] = useLexicalComposerContext8();
  return /* @__PURE__ */ jsx5(
    "div",
    {
      ref: forwardedRef,
      className: clsx3("m-0 -mt-[248px] leading-[55px] inline-block cursor-text truncate p-2", toolbox ? "top-10" : "top-0", className),
      onClick: () => editor.focus(),
      tabIndex: -1,
      role: "button",
      "aria-hidden": "true",
      ...props,
      children: /* @__PURE__ */ jsx5(BasicRichTextEditor, { isPlaceholder: true, id, readOnly: true, value })
    }
  );
});
var BasicRichTextEditor = forwardRef(function BasicRichTextEditor2({
  id,
  value,
  onChange,
  children,
  className,
  placeholder,
  isPlaceholder,
  readOnly = false,
  disabled = false,
  editorClassName = "",
  maxChar,
  ...props
}, forwardedRef) {
  const isClean = React.useRef(true);
  const [isLinkEditMode, setIsLinkEditMode] = useState4(false);
  const [floatingAnchorElem, setFloatingAnchorElem] = useState4(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState4(false);
  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };
  useEffect9(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport = CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;
      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener("resize", updateViewPortWidth);
    return () => {
      window.removeEventListener("resize", updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);
  const initialConfig = React.useMemo(() => {
    return {
      namespace: "Comment",
      theme: {
        text: {
          bold: clsx3("font-bold"),
          italic: clsx3("italic"),
          underline: clsx3("underline"),
          strikethrough: clsx3("line-through")
        },
        list: {
          listitem: "PlaygroundEditorTheme__listItem",
          listitemChecked: "PlaygroundEditorTheme__listItemChecked",
          listitemUnchecked: "PlaygroundEditorTheme__listItemUnchecked",
          nested: {
            listitem: clsx3("after:hidden before:hidden list-none")
          },
          ol: clsx3("list-decimal pl-6"),
          ul: clsx3(readOnly ? " pl-0 list-none custom-bullet" : "pl-6"),
          ulDepth: [clsx3("list-disc"), clsx3("list-[circle]"), clsx3("list-[square]")],
          olDepth: [clsx3("list-decimal"), clsx3("list-[upper-alpha]"), clsx3("list-[lower-alpha]"), clsx3("list-[upper-roman]"), clsx3("list-[lower-roman]")]
        },
        link: "text-cherry-red hover:underline hover:cursor-pointer"
      },
      onError: (error) => console.error(error),
      // Any custom nodes go here
      id,
      nodes: [ParagraphNode, ListNode2, ListItemNode, LinkNode2, AutoLinkNode],
      editorState: value,
      editable: !disabled && !readOnly
    };
  }, [readOnly, disabled]);
  return /* @__PURE__ */ jsx5(LexicalComposer, { initialConfig, children: /* @__PURE__ */ jsxs4("div", { ref: forwardedRef, className: clsx3("relative flex w-full flex-col", disabled && "bg-neutral-100", className), ...props, children: [
    !readOnly && /* @__PURE__ */ jsx5(ToolbarPlugin, { setIsLinkEditMode, disabled }),
    /* @__PURE__ */ jsxs4(Fragment, { children: [
      /* @__PURE__ */ jsx5(
        RichTextPlugin,
        {
          contentEditable: /* @__PURE__ */ jsx5(
            "div",
            {
              className: clsx3(
                "editor-scroller relative flex z-0 overflow-auto outline-0 min-h-[14.5rem]",
                !readOnly && "min-h-[12.5rem]"
                //Min height is only needed in edition mode.
              ),
              children: /* @__PURE__ */ jsx5("div", { className: "flex-auto resize-y relative", ref: onRef, children: /* @__PURE__ */ jsx5(
                ContentEditable,
                {
                  className: clsx3(
                    "relative 0 w-full resize-none overflow-auto focus:outline-none",
                    isPlaceholder ? "italic text-neutral-600" : "text-neutral-900",
                    editorClassName
                  )
                }
              ) })
            }
          ),
          placeholder: placeholder ? /* @__PURE__ */ jsx5(LexicalPlaceholder, { id: `${id}.placeholder`, value: placeholder, toolbox: true }) : null,
          ErrorBoundary: LexicalErrorBoundary
        }
      ),
      /* @__PURE__ */ jsx5(TabIndentationPlugin, {}),
      /* @__PURE__ */ jsx5(ListPlugin, {}),
      /* @__PURE__ */ jsx5(LinkPlugin, { validateUrl }),
      /* @__PURE__ */ jsx5(LexicalAutoLinkPlugin, {}),
      readOnly ? /* @__PURE__ */ jsx5(ListenValueChangePlugin, { value }) : /* @__PURE__ */ jsxs4(Fragment, { children: [
        floatingAnchorElem && !isSmallWidthViewport && /* @__PURE__ */ jsx5(FloatingLinkEditorPlugin, { anchorElem: floatingAnchorElem, isLinkEditMode, setIsLinkEditMode }),
        /* @__PURE__ */ jsx5(ResetEditorPlugin, { isDirty: !isClean.current, defaultValue: initialConfig.editorState, currentValue: value }),
        /* @__PURE__ */ jsx5(
          OnChangePlugin,
          {
            onChange: (editorState) => {
              if (isClean)
                isClean.current = false;
              const json = editorState.toJSON();
              const string = JSON.stringify(json);
              if (onChange)
                onChange(string === EMPTY_RICHTEXT ? void 0 : string);
            },
            ignoreSelectionChange: true
          }
        ),
        /* @__PURE__ */ jsx5(TabFocusPlugin, {}),
        /* @__PURE__ */ jsx5(HistoryPlugin, {}),
        /* @__PURE__ */ jsx5(MarkdownShortcutPlugin, { transformers: TRANSFORMERS }),
        maxChar ? /* @__PURE__ */ jsx5(MaxLengthPlugin, { maxLength: maxChar }) : null
      ] }),
      children
    ] })
  ] }) });
});
export {
  BasicRichTextEditor,
  getLexicalTextContentLength,
  getSimpleRichText
};
